
.object-page .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #eaedf1;
}
.object-page .links .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 40px;
  height: 40px;
  color: inherit;
  text-decoration: none;
  width: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #d8dee7;
  font-size: 14px;
}
.object-page .links .link:last-child {
  border-right: 0;
}
.object-page .links .icon {
  margin-right: 10px;
}
.object-page .links .icon.back {
  width: 12px;
  height: 10px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,24,20%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%2300a651%27%20stroke-width=%273%27%20d=%27m11,1-9,9,9,9m-9-9h22%27/%3E%3C/svg%3E");
}
.object-page .links .icon.search {
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,28,28%27%3E%3Cg%20fill=%27none%27%20stroke=%27%2300a651%27%3E%3Cpath%20stroke-width=%272%27%20d=%27m8,1a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5zm13,10.5a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5zm-13,10.5a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5z%27/%3E%3Cpath%20stroke-width=%273%27%20d=%27m0,3.5h5m6,0h17m0,10.5h-5m-5,0h-18m0,10.5h5m6,0h17%27/%3E%3C/g%3E%3C/svg%3E");
}
.object-page .info {
  padding: 20px 15px;
}
.object-page .info .title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.object-page .info .price {
  color: #00a651;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.object-page .info .price .ruble {
  height: 19px;
  width: 9px;
}
.object-page .info .subway {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 300;
  line-height: 25px;
  max-width: 100%;
}
.object-page .info .subway span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.object-page .info .subway .metro {
  width: 15px;
  min-width: 15px;
  height: 12px;
  display: block;
  margin-right: 5px;
}
.object-page .info .subway .transport {
  width: 16px;
  min-width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,16,16%27%3E%3Cpath%20fill=%27%236b737f%27%20d=%27m3,0a2,2,0,0,0-2,2v2a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1v4a2,1.5,0,0,0,2,1.5,1.5,1.5,0,0,0,3,0h4a1.5,1.5,0,0,0,3,0,2,1.5,0,0,0,2-1.5v-4a1,1,0,0,0,1-1v-3a1,1,0,0,0-1-1v-2a2,2,0,0,0-2-2zm8,.5a.5,.5,0,0,1,0,1h-6a.5,.5,0,0,1,0-1zm2,1.5a1,1,0,0,1,1,1v6a1,1,0,0,1,-1,1h-10a1,1,0,0,1,-1-1v-6a1,1,0,0,1,1-1zm-2.25,9.5a.75,.75,0,0,1,1.5,0v1a.75,.75,0,0,1-1.5,0zm-7,0a.75,.75,0,0,1,1.5,0v1a.75,.75,0,0,1-1.5,0z%27/%3E%3C/svg%3E");
  margin: 0 5px;
}
.object-page .info .subway .walk {
  width: 16px;
  min-width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,24,32%27%3E%3Cpath%20fill=%27%236b737f%27%20d=%27m14,0a4,4,0,0,1,0,8,4,4,0,0,1,0-8zm-3,8a12,12,0,0,0-9,11,1.5,1.5,0,0,0,3,0,12,12,0,0,1,3-6,12,12,0,0,1-.5,6,25,25,0,0,1-7,10,1.5,1.5,0,0,0,2.5,2.5,25,25,0,0,0,6.5-9,.5,.5,0,0,1,.7-.2,25,25,0,0,1,5.7,8.9,1.5,1.5,0,0,0,2.8-1.5,25,25,0,0,0-6-9.5,2,2,0,0,1-1-2,20,20,0,0,1,1-5,12,12,0,0,0,9.8,3.5,1.5,1.5,0,0,0,0-3,12,12,0,0,1-9-4.3,2.5,2.5,0,0,0-2.5-1.4z%27/%3E%3C/svg%3E");
  margin: 0 5px;
}
.object-page .info .address,
.object-page .info .dates {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.object-page .progress .date-changer {
  display: block;
  width: 100%;
  position: relative;
  border-top: 1px solid #eaedf1;
  height: 40px;
}
.object-page .progress .date-changer::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #323a45;
  border-bottom: 1px solid #323a45;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  right: 20px;
  margin-top: -6px;
  z-index: 0;
}
.object-page .progress .date-changer select {
  width: 100%;
  line-height: 40px;
  height: 40px;
  padding: 0 50px 0 15px;
  font-size: 14px;
  color: #323a45;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.object-page .map {
  height: 50vw;
}
.object-page .tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}
.object-page .tabs .tab {
  background: #eaedf1;
  width: 50%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-right: 1px solid #d8dee7;
}
.object-page .tabs .tab:first-of-type {
  width: 20%;
}
.object-page .tabs .tab:last-of-type {
  width: 30%;
  border-right: 0;
}
.object-page .tabs input {
  display: none;
}
.object-page .tabs input:checked + .tab {
  background: #00a651;
  border-color: #00a651;
  color: #fff;
}
.object-page .spoiler {
  border-bottom: 1px solid #eaedf1;
  position: relative;
}
.object-page .spoiler .filter-btn {
  border: 1px solid #57aeea;
  color: #57aeea;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  top: 10px;
  right: 40px;
  padding: 5px 15px 4px;
  line-height: 12px;
}
.object-page .spoiler .filter-btn .count {
  color: #fff;
  background: #f90001;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-align: center;
  line-height: 12px;
  font-size: 9px;
}
.object-page .spoiler .header {
  line-height: 40px;
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.object-page .spoiler .header .icon {
  width: 24px;
  height: 20px;
  margin-right: 10px;
}
.object-page .spoiler .header .icon.plan {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,32,30%27%3E%3Cpath%20fill=%27%2300a651%27%20d=%27m0,0h27v11h-11v-8h-4v24h12v-7l6-6,2,2-5,5v9h-27zm3,3v24h6v-24zm16,0v5h5v-5%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.pdf {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,40,50%27%3E%3ClinearGradient%20id=%27a%27%20x1=%2750%%27%20y1=%270%%27%20x2=%2750%%27%20y2=%27100%%27%3E%3Cstop%20offset=%270%%27%20stop-color=%27%2300a651%27/%3E%3Cstop%20offset=%27100%%27%20stop-color=%27%23009e4d%27/%3E%3C/linearGradient%3E%3Cpath%20fill=%27url(%23a)%27%20d=%27m11,0h27a2,2,0,0,1,2,2v46a2,2,0,0,1-2,2h-36a2,2,0,0,1-2-2v-37z%27/%3E%3Cpath%20fill=%27%23eefff6%27%20d=%27m11,0v11h-11z%27/%3E%3Cpath%20fill=%27%23000%27%20opacity=%27.3%27%20d=%27m11,0v11l11-11z%27/%3E%3Cpath%20fill=%27none%27%20stroke=%27%23fff%27%20stroke-width=%271.5%27%20d=%27m9,35v-13h2a4,4,0,0,1,4,4a4,4,0,0,1-4,4h-2zm9,-.75h2a4,6.125,0,0,0,4-6.125a4,6.125,0,0,0-4-6.125h-2zm9,.75v-13h5m0,6h-5%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.actions {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,82,67%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%2300a651%27%20stroke-linejoin=%27round%27%20stroke-linecap=%27round%27%20stroke-width=%275%27%20d=%27M37,2.5h22.5a5,5,0,0,1,5,5v22.5l-34.5,34.5l-27.5-27.5l34.5-34.5zm15,8a4,4,0,0,1,0,8a4,4,0,0,1,0-8zm1,4a12,10,0,0,1,20,0,12,10,0,0,0,6.5,5m-39.5,23,-10,10,-10-10%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.about {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,32,32%27%3E%3Cpath%20fill=%27%2300a651%27%20d=%27m0,14.4,11-4.7v-5.4l9-4.3,12,4.3v27.7h-32zm2,1.3v14.3h12v-19.4zm1.5,.8,9-3.5v2l-9,3.5zm0,3.5,9-2.5v2l-9,2.5zm0,3.5,9-1.5v2l-9,1.5zm0,3.5,9-.5v2l-9,.5zm12.5,3h6v-17.3l-6-2.2zm8,0h6v-24.3l-9.9-3.6-7.1,3.5v3.2l2-.9,9,3.4zm2-5h2v-2h-2zm0-4h2v-2h-2zm0-4h2v-2h-2zm0-4h2v-2h-2zm0-4h2v-2h-2z%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.advantage {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,32,32%27%3E%3Cpath%20fill=%27%2300a651%27%20d=%27m0,0h32v32h-32zm3,3v26h26v-26zm11,4v7h-7v4h7v7h4v-7h7v-4h-7v-7z%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.bank {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,36,30%27%3E%3Cpath%20fill=%27none%27%20stroke-width=%272%27%20stroke=%27%2300a651%27%20d=%27M24,9a11,10,0,0,1,0,20,11,10,0,0,1,0-20zM1,6a11.5,5,0,0,1,23,0,11.5,5,0,0,1-23,0zm0,0v16a11.5,5,0,0,0,15.5,4.7m-15.5-10a11.5,5,0,0,0,12,5m-12-10.3a11.5,5,0,0,0,12,5%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header .icon.doc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,32,32%27%3E%3Cpath%20fill=%27none%27%20stroke-width=%273%27%20stroke=%27%2300a651%27%20d=%27m1.5,1.5h29v29h-29zm4,5h21m0,5h-21m0,5h21m-8,5h-13m24,2.5h-5.5v5%27/%3E%3C/svg%3E");
}
.object-page .spoiler .header::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #323a45;
  border-bottom: 1px solid #323a45;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.object-page .spoiler > input {
  display: none;
}
.object-page .spoiler > input:checked + .header {
  border-bottom: 1px solid #eaedf1;
}
.object-page .slider .container .action {
  font-size: 14px;
  padding: 20px 15px;
  white-space: normal;
}
.object-page .slider .container .action .image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.object-page .slider .container .action .title {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 0;
}
.object-page .slider .container .action .text {
  text-align: center;
  margin-bottom: 15px;
}
.object-page .slider .container .advantage {
  font-size: 14px;
  white-space: normal;
}
.object-page .slider .container .advantage .title {
  padding: 20px 15px 0;
  text-transform: uppercase;
  font-weight: 600;
}
.object-page .slider .container .bank {
  padding: 20px 15px;
}
.object-page .slider .container .bank .image {
  width: 170px;
  height: auto;
  display: block;
  margin: auto;
}
.object-page .slider .container .doc {
  font-size: 14px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: normal;
  text-decoration: none;
  color: inherit;
  padding: 20px 50px;
}
.object-page .slider .container .doc::before {
  content: "";
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,25,36%27%3E%3Cg%20stroke-width=%272%27%3E%3Cpath%20fill=%27%23fff%27%20stroke=%27%23323a45%27%20d=%27m24,9V35H1V1h15zm0,1h-9V1%27/%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m5,17h15m0,4h-15m0,4h15%27/%3E%3C/g%3E%3C/svg%3E") no-repeat center;
  width: 45px;
  min-width: 45px;
  height: 55px;
  display: block;
}
.object-page .slider .container .doc .name {
  display: block;
  padding-left: 10px;
}
.object-page .slider .container .doc .download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
.object-page .slider .container .doc .download::before {
  content: "";
  width: 20px;
  min-width: 20px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,40,36%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%2300a651%27%20stroke-width=%275%27%20d=%27m0,33.5h40m-7-19l-13,13-13-13m13,10v-25%27/%3E%3C/svg%3E") no-repeat center;
  background-size: contain;
  margin-right: 5px;
}
.object-page .presentation {
  background: #eaedf1;
  padding: 20px 15px;
  text-align: center;
  color: #52575e;
}
.object-page .presentation .icon {
  width: 40px;
  height: 50px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,40,50%27%3E%3ClinearGradient%20id=%27a%27%20x1=%2750%%27%20y1=%270%%27%20x2=%2750%%27%20y2=%27100%%27%3E%3Cstop%20offset=%270%%27%20stop-color=%27%2300a651%27/%3E%3Cstop%20offset=%27100%%27%20stop-color=%27%23009e4d%27/%3E%3C/linearGradient%3E%3Cpath%20fill=%27url(%23a)%27%20d=%27m11,0h27a2,2,0,0,1,2,2v46a2,2,0,0,1-2,2h-36a2,2,0,0,1-2-2v-37z%27/%3E%3Cpath%20fill=%27%23eefff6%27%20d=%27m11,0v11h-11z%27/%3E%3Cpath%20fill=%27%23000%27%20opacity=%27.3%27%20d=%27m11,0v11l11-11z%27/%3E%3Cpath%20fill=%27none%27%20stroke=%27%23fff%27%20stroke-width=%271.5%27%20d=%27m9,35v-13h2a4,4,0,0,1,4,4a4,4,0,0,1-4,4h-2zm9,-.75h2a4,6.125,0,0,0,4-6.125a4,6.125,0,0,0-4-6.125h-2zm9,.75v-13h5m0,6h-5%27/%3E%3C/svg%3E");
  margin-bottom: 10px;
}
.object-page .presentation .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 10px;
}
.object-page .presentation .title small {
  display: block;
  font-size: 16px;
  font-weight: normal;
}
.object-page .presentation p {
  font-size: 16px;
  margin-bottom: 35px;
}
.object-page .presentation input {
  height: 50px;
  line-height: 50px;
  border: 1px solid #eaedf1;
  padding: 0 15px;
  font-size: 16px;
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.object-page .presentation .btn {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  border-radius: 0;
  margin: 0 20px;
  width: calc(100% - 40px);
}
.object-page .presentation .why-phone {
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  text-decoration: underline;
  position: relative;
  line-height: 20px;
  height: 20px;
  margin-bottom: 15px;
}
.object-page .presentation .why-phone .pop {
  position: absolute;
  bottom: 30px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  left: 0;
  padding: 10px;
  text-transform: none;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.2);
          box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.object-page .presentation .why-phone .pop::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 0;
}