
.object-layouts {
  min-height: 40px;
}
.object-layouts .spoiler label.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 50px;
}
.object-layouts .spoiler label.header .price {
  color: #00a651;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.object-layouts .spoiler label.header .price .ruble {
  width: 8px;
  height: 12px;
  margin-left: 5px;
}
.object-layouts .head {
  padding: 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #eaedf1;
  height: 40px;
}
.object-layouts .layout {
  height: 40px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 50px;
  border-bottom: 1px solid #eaedf1;
}
.object-layouts .layout::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #323a45;
  border-top: 1px solid #323a45;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.object-layouts .layout .price {
  color: #00a651;
}