
.auth-screen {
  height: 100vh;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #d8dee7;
}
.auth-screen .login-form {
  padding: 20px;
  max-width: 320px;
  width: 100%;
}
.auth-screen .login-form input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 15px;
  border-radius: 5px;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}