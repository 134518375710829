
.coupon-popup .label {
  background: url("https://cdn.mirndv.ru/static/img/coupon.png") no-repeat center top;
  width: 100%;
  height: 368px;
  margin-top: -40px;
  text-align: center;
  padding: 160px 20px 0 0;
}
.coupon-popup .number {
  color: #00a651;
  font-size: 80px;
  line-height: 80px;
  font-weight: bold;
}
.coupon-popup .green {
  color: #00a651;
  font-size: 32px;
}
.coupon-popup .white {
  color: #fff;
  font-size: 19px;
}
.coupon-popup input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 15px;
  border-radius: 5px;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}