
.layout-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.layout-popup .btn {
  position: fixed;
  bottom: 15px;
  width: calc(100% - 30px);
  left: 15px;
}
.layout-popup .title {
  background: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 7px 10px;
  line-height: 22px;
}
.layout-popup .area,
.layout-popup .price {
  color: #fff;
  width: 50%;
  height: 40px;
  line-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10px;
}
.layout-popup .area {
  background: #323a45;
}
.layout-popup .price {
  background: #00a651;
}
.layout-popup .price .ruble {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,10,14%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%23fff%20%27%20stroke-width=%271.5%27%20d=%27M1.75,14,1.75,0.75,5.25,0.75A4,4,0,0,1,5.25,8.75L1.75,8.75M0,11.5,8,11.5%27/%3E%3C/svg%3E");
  height: 19px;
  width: 9px;
  margin-left: 5px;
}
.layout-popup .image {
  background: #fff;
  padding: 10px;
  height: 270px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.layout-popup .image img {
  width: 100%;
  height: auto;
}
.layout-popup .image.reserved {
  position: relative;
}
.layout-popup .image.reserved::before {
  content: "";
  background: url("../../img/reserved.png") no-repeat center;
  width: 211px;
  height: 106px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -53px 0 0 -105px;
}
.layout-popup .image-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.layout-popup .image-select label {
  height: 40px;
  background: #bababa;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}
.layout-popup .image-select input {
  display: none;
}
.layout-popup .image-select input:checked + label {
  background: #00a651;
}
.layout-popup .circles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 35px;
}
.layout-popup .circles .circle {
  width: 11px;
  height: 11px;
  background: #fff;
  border: 2px solid #eaedf1;
  border-radius: 50%;
}
.layout-popup .circles .circle:nth-child(2),
.layout-popup .circles .circle:first-child {
  border-width: 3px;
}
.layout-popup .circles .circle:nth-child(2).small,
.layout-popup .circles .circle:first-child.small {
  border-width: 4px;
}
.layout-popup .circles .circle.current {
  background: #00a651;
  border-width: 2px;
}
.layout-popup .circles .circle.current ~ .circle:nth-child(2) {
  border-width: 2px;
}
.layout-popup .circles .circle.current ~ .circle:last-child {
  border-width: 4px;
}
.layout-popup .circles .circle.current ~ .circle:nth-last-of-type(2) {
  border-width: 3px;
}
.layout-popup .circles .circle.current + .circle:last-child {
  border-width: 3px;
}
.layout-popup .info {
  width: 80%;
  margin: 10px 0 60px;
}
.layout-popup .info .line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 25px;
}
.layout-popup .info .line strong,
.layout-popup .info .line span {
  width: 50%;
}
.layout-popup .info .line span {
  color: #6b737f;
}
.layout-popup .arrow {
  position: absolute;
  height: calc(100% - 60px);
  top: 40px;
  width: 40px;
}
.layout-popup .arrow::before,
.layout-popup .arrow::after {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  border: 1px solid #323a45;
  width: 15px;
  margin-left: -8px;
}
.layout-popup .arrow::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.layout-popup .arrow::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.layout-popup .arrow.left {
  left: 0;
}
.layout-popup .arrow.left::before {
  margin-top: 6px;
}
.layout-popup .arrow.left::after {
  margin-top: -6px;
}
.layout-popup .arrow.right {
  right: 0;
}
.layout-popup .arrow.right::before {
  margin-top: -6px;
}
.layout-popup .arrow.right::after {
  margin-top: 6px;
}