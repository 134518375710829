
.layout-popup .presentation-popup h3 {
  font-weight: bold;
  margin-bottom: 30px;
  color: #6b737f;
}
.layout-popup .presentation-popup input {
  display: none;
}
.layout-popup .presentation-popup input + label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 80px;
  min-height: 30px;
  margin-bottom: 25px;
}
.layout-popup .presentation-popup input + label::before {
  content: '';
  background: #d8dee7;
  width: 58px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.layout-popup .presentation-popup input + label::after {
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.layout-popup .presentation-popup input:checked + label::before {
  background: #00a651;
}
.layout-popup .presentation-popup input:checked + label::after {
  left: 30px;
}
.layout-popup .presentation-popup .btn {
  position: static;
}