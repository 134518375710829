
.layouts .layout {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #eaedf1;
}
.layouts .layout .title {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  word-wrap: break-word;
  width: 100%;
}
.layouts .layout .price {
  color: #00a651;
  display: block;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.layouts .layout .price .ruble {
  height: 19px;
  width: 9px;
}
.layouts .layout .photo {
  width: 145px;
  height: 145px;
}
.layouts .layout .info {
  width: calc(100% - 145px);
  padding-left: 15px;
}
.layouts .layout .info .title {
  text-transform: uppercase;
}
.layouts .layout .info .date {
  font-weight: 500;
  margin-bottom: 5px;
}
.layouts .layout .info .subway {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 25px;
  max-width: 100%;
}
.layouts .layout .info .subway span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.layouts .layout .info .subway .metro {
  width: 15px;
  min-width: 15px;
  height: 12px;
  display: block;
  margin-right: 5px;
}
.layouts .layout .info .address {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}