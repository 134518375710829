
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.main-menu .link {
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  line-height: 30px;
  height: 40px;
  display: block;
  padding: 5px 10px;
  text-align: center;
  border-bottom: 1px solid #d8dee7;
}