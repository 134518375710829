
.search-form {
  background: #eaedf1;
}
.search-form input {
  outline: 0;
}
.search-form .free-input {
  padding: 20px 15px 5px;
  position: relative;
}
.search-form .free-input label {
  display: block;
  width: 100%;
  background: #fff;
  padding: 0;
  border-radius: 5px;
  position: relative;
}
.search-form .free-input label::before,
.search-form .free-input label::after {
  content: "";
  border: 2px solid #6b737f;
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 0;
}
.search-form .free-input label::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -8px 0 0 0;
}
.search-form .free-input label::after {
  width: 6px;
  height: 0;
  border-width: 1px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #6b737f;
  border-radius: 1px;
  margin: 5px -3px 0 0;
}
.search-form .free-input input {
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  display: block;
  background: transparent;
  font-size: 12px;
  padding: 0 15px;
  z-index: 1;
  position: relative;
}
.search-form .free-input .autocomplete {
  position: absolute;
  top: 65px;
  z-index: 1;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px 15px;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.1);
          box-shadow: 0 5px 5px rgba(0,0,0,0.1);
}
.search-form .free-input .content {
  max-height: 300px;
  overflow: scroll;
}
.search-form .free-input .line {
  line-height: 25px;
}
.search-form .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 15px;
}
.search-form .tags .tag {
  background: #00a651;
  color: #fff;
  font-size: 14px;
  line-height: 15px;
  padding: 4px 5px 6px 10px;
  border-radius: 5px;
  margin: 5px 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.search-form .tags .tag .remove {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 10px;
  position: relative;
}
.search-form .tags .tag .remove::before,
.search-form .tags .tag .remove::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid #fff;
  width: 8px;
  margin: -1px 0 0 -5px;
}
.search-form .tags .tag .remove::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.search-form .tags .tag .remove::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.search-form label {
  font-size: 14px;
  line-height: 50px;
  padding: 0 15px;
  height: 50px;
  text-transform: uppercase;
  display: block;
}
.search-form .rooms {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.search-form .rooms label {
  color: #6b737f;
  font-size: 12px;
  width: 100%;
  text-transform: none;
  background: #fff;
}
.search-form .rooms .room {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1px;
  width: 100%;
  text-align: center;
}
.search-form .rooms .room:first-child label {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.search-form .rooms .room:last-child label {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.search-form .rooms .room.more {
  position: relative;
}
.search-form .rooms .room.more .rooms {
  position: absolute;
  top: 41px;
  right: 0;
  padding: 0;
  width: calc(100vw - 30px);
  z-index: 1;
  background: #eaedf1;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.1);
          box-shadow: 0 5px 5px rgba(0,0,0,0.1);
  display: none;
}
.search-form .rooms .room.more .rooms .room label {
  border-radius: 0;
}
.search-form .rooms .room.more .rooms .room:first-child label {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.search-form .rooms .room.more .rooms .room:last-child label {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.search-form .rooms .room.more input:checked ~ .rooms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search-form .rooms input {
  display: none;
}
.search-form .rooms input:checked + label {
  -webkit-box-shadow: 0 0 0 1px #00a651;
          box-shadow: 0 0 0 1px #00a651;
}
.search-form .spoiler {
  border-bottom: 1px solid #d8dee7;
}
.search-form .spoiler .header {
  line-height: 50px;
  height: 50px;
  padding: 0 15px;
  font-size: 14px;
  position: relative;
}
.search-form .spoiler .header::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #323a45;
  border-bottom: 1px solid #323a45;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.search-form .spoiler .content {
  display: none;
}
.search-form .spoiler .value {
  display: none;
}
.search-form .spoiler.changed .header {
  background: #fff;
}
.search-form .spoiler.changed .value {
  position: absolute;
  font-size: 14px;
  color: #00a651;
  line-height: 40px;
  top: 0;
  right: 50px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-form .spoiler.active {
  position: relative;
}
.search-form .spoiler.active .value {
  display: none;
}
.search-form .spoiler.active .header::before {
  -webkit-transform: none;
          transform: none;
  border: 0;
  height: 40px;
  content: "Готово";
  color: #00a651;
  width: auto;
  top: 0;
  line-height: 40px;
  margin: 0;
}
.search-form .spoiler.active .content {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.1);
          box-shadow: 0 5px 5px rgba(0,0,0,0.1);
  position: absolute;
  left: 0;
  width: 100%;
  top: 50px;
  z-index: 1;
}
.search-form .spoiler.active .content input {
  height: 50px;
  line-height: 50px;
  border: 1px solid #eaedf1;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  display: block;
  width: 100%;
}
.search-form .spoiler.active .content .control {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.search-form .spoiler.active .content .control .line {
  width: 100%;
  height: 50px;
  position: relative;
}
.search-form .spoiler.active .content .control .line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #eaedf1;
  margin-top: -1px;
}
.search-form .spoiler.active .content .control .line .point {
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  background: #00a651;
  margin-top: -1px;
}
.search-form .spoiler.active .content .control .line .point::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #00a651;
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -10px -10px 0 0;
}
.search-form .spoiler.active .content .control.twice .line .point::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #00a651;
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin: -10px 0 0 -10px;
}
.search-form .spoiler.active .content .control.twice input {
  width: 45%;
}
.search-form .spoiler.active .content .year label {
  padding: 0 25px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-transform: none;
  border-bottom: 1px solid #eaedf1;
}
.search-form .spoiler.active .content .year input {
  display: none;
}
.search-form .spoiler.active .content .year input:checked + label {
  color: #00a651;
  font-weight: 400;
}
.search-form .spoiler.active .content .year:last-child label {
  border-bottom: 0;
}
.search-form .spoiler.active .content .item label {
  padding: 0 25px 0 60px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-transform: none;
  border-bottom: 1px solid #eaedf1;
  position: relative;
}
.search-form .spoiler.active .content .item label::before {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #eaedf1;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -9px;
}
.search-form .spoiler.active .content .item:last-child label {
  border-bottom: 0;
}
.search-form .spoiler.active .content .item input {
  display: none;
}
.search-form .spoiler.active .content .item input:checked + label::after {
  content: "";
  width: 6px;
  height: 12px;
  border-right: 2px solid #00a651;
  border-bottom: 2px solid #00a651;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -9px;
}
.search-form .btn-wrap {
  padding: 20px 15px;
}