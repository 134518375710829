
.objects-list .object {
  padding: 20px 15px;
  border-bottom: 10px solid #eaedf1;
  color: inherit;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.objects-list .object a {
  color: inherit;
  text-decoration: none;
}
.objects-list .object .title {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  line-height: 20px;
  width: 100%;
}
.objects-list .object .builder {
  font-size: 11px;
  text-align: left;
  color: #6b737f;
  width: 100%;
}
.objects-list .object .columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-top: 5px;
  width: 100%;
}
.objects-list .object .columns .col {
  width: 50%;
  max-width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.objects-list .object .columns .col + .col {
  padding-left: 10px;
}
.objects-list .object .columns + .columns .col {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.objects-list .object .columns + .columns + .columns .col {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.objects-list .object .columns.first .col:first-child {
  width: 100%;
  max-width: 100%;
}
.objects-list .object .dates {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
}
.objects-list .object .dates span {
  white-space: nowrap;
  display: inline-block;
  margin-left: 5px;
}
.objects-list .object .count {
  color: #6b737f;
  font-size: 11px;
  text-align: right;
}
.objects-list .object .photo,
.objects-list .object .item {
  width: calc((100vw - 30px) / 2);
  height: auto;
  display: inline-block;
}
.objects-list .object .slider .container {
  min-height: auto;
}
.objects-list .subway {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  max-width: 100%;
}
.objects-list .subway span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.objects-list .subway .metro {
  width: 11px;
  min-width: 11px;
  height: 9px;
  display: block;
  margin-right: 5px;
}
.objects-list .subway .transport {
  width: 10px;
  min-width: 10px;
  height: 18px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,16,16%27%3E%3Cpath%20fill=%27%23323a45%27%20d=%27m3,0a2,2,0,0,0-2,2v2a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1v4a2,1.5,0,0,0,2,1.5,1.5,1.5,0,0,0,3,0h4a1.5,1.5,0,0,0,3,0,2,1.5,0,0,0,2-1.5v-4a1,1,0,0,0,1-1v-3a1,1,0,0,0-1-1v-2a2,2,0,0,0-2-2zm8,.5a.5,.5,0,0,1,0,1h-6a.5,.5,0,0,1,0-1zm2,1.5a1,1,0,0,1,1,1v6a1,1,0,0,1,-1,1h-10a1,1,0,0,1,-1-1v-6a1,1,0,0,1,1-1zm-2.25,9.5a.75,.75,0,0,1,1.5,0v1a.75,.75,0,0,1-1.5,0zm-7,0a.75,.75,0,0,1,1.5,0v1a.75,.75,0,0,1-1.5,0z%27/%3E%3C/svg%3E");
  margin: 0 5px;
}
.objects-list .subway .walk {
  width: 9px;
  min-width: 9px;
  height: 18px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,24,32%27%3E%3Cpath%20fill=%27%23323a45%27%20d=%27m14,0a4,4,0,0,1,0,8,4,4,0,0,1,0-8zm-3,8a12,12,0,0,0-9,11,1.5,1.5,0,0,0,3,0,12,12,0,0,1,3-6,12,12,0,0,1-.5,6,25,25,0,0,1-7,10,1.5,1.5,0,0,0,2.5,2.5,25,25,0,0,0,6.5-9,.5,.5,0,0,1,.7-.2,25,25,0,0,1,5.7,8.9,1.5,1.5,0,0,0,2.8-1.5,25,25,0,0,0-6-9.5,2,2,0,0,1-1-2,20,20,0,0,1,1-5,12,12,0,0,0,9.8,3.5,1.5,1.5,0,0,0,0-3,12,12,0,0,1-9-4.3,2.5,2.5,0,0,0-2.5-1.4z%27/%3E%3C/svg%3E");
  margin: 0 5px;
}
.objects-list .address {
  font-weight: 500;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 19px;
}
.objects-list .address .point {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,22,32%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%23323a45%27%20stroke-width=%273%27%20d=%27m1.5,11a9.5,9.5,0,0,1,19,0,9.5,9.5,0,0,1-1,4l-8.5,14-8.5-14a9.5,9.5,0,0,1-1-4zm5.5,0a4,4,0,0,1,8,0,4,4,0,0,1-8,0z%27/%3E%3C/svg%3E");
  height: 12px;
  width: 9px;
  min-width: 9px;
  display: block;
  margin-right: 5px;
}
.objects-list .district {
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
}
.objects-list .prices {
  display: block;
  font-size: 11px;
}
.objects-list .prices .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 25px;
}
.objects-list .prices .price span + span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00a651;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
}
.objects-list .prices .ruble {
  height: 10px;
  width: 7px;
  margin-left: 5px;
}
.objects-list .btn {
  font-size: 11px;
  line-height: 29px;
  height: 29px;
  padding: 0 5px;
  font-weight: 500;
}
.objects-list .phone {
  background: #f78906;
  color: #fff !important;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.objects-list .phone::before {
  content: "";
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,30,30%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%23fff%27%20stroke-width=%271.3%27%20d=%27m3,1.6c-2,2-2.5,2-2.4,5a31,31,0,0,0,22.6,22.6c2.5,0,3,0,5-2.5c2-2,1-4-1.5-6c-3.5-2-4-1.5-5.5-.5c-2,2-2,2-5,0a30,30,0,0,1-7-7c-2-3-1-3.5,.5-5c1.5-2,.7-3-.5-5c-1.8-1.8-3-4-6-1.7z%27/%3E%3C/svg%3E");
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.objects-list .phone::after {
  content: "Позвонить в отдел продаж";
  font-size: 12px;
}
.get-pdf-form {
  border-bottom: 10px solid #eaedf1;
}