
.slider {
  overflow: hidden;
}
.slider .container {
  display: block;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 0;
  white-space: nowrap;
  min-height: 49.8vw;
  position: relative;
}
.slider .container .item {
  width: 100vw;
  display: inline-block;
  vertical-align: top;
}
.slider .container .item img {
  width: 100%;
  height: auto;
  display: block;
}
.slider .container.blocked::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.slider .circles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 33px;
}
.slider .circles .circle {
  width: 13px;
  height: 13px;
  background: #eaedf1;
  border: 3px solid #fff;
  border-radius: 50%;
  display: block;
}
.slider .circles .circle.current {
  background: #00a651;
}
.slider .circles input {
  display: none;
}