
.result-popup {
  background: url("../../img/cake.png") no-repeat center bottom;
  background-size: contain;
  text-align: center;
  font-size: 20px;
  height: 440px;
}
.result-popup .btn {
  margin-top: 260px;
}