
.filter-popup-block .popup {
  padding-left: 0;
  padding-right: 0;
}
.filter-popup input {
  outline: 0;
}
.filter-popup label {
  font-size: 14px;
  line-height: 50px;
  padding: 0 15px;
  height: 50px;
  text-transform: uppercase;
  display: block;
}
.filter-popup .rooms {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.filter-popup .rooms label {
  color: #6b737f;
  font-size: 12px;
  width: 100%;
  text-transform: none;
  background: #fff;
}
.filter-popup .rooms .room {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1px;
  width: 100%;
  text-align: center;
}
.filter-popup .rooms .room:first-child label {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.filter-popup .rooms .room:last-child label {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.filter-popup .rooms .room.more {
  position: relative;
}
.filter-popup .rooms .room.more .rooms {
  position: absolute;
  top: 41px;
  right: 0;
  padding: 0;
  width: calc(100vw - 30px);
  z-index: 1;
  background: #eaedf1;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.1);
          box-shadow: 0 5px 5px rgba(0,0,0,0.1);
  display: none;
}
.filter-popup .rooms .room.more .rooms .room label {
  border-radius: 0;
}
.filter-popup .rooms .room.more .rooms .room:first-child label {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.filter-popup .rooms .room.more .rooms .room:last-child label {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.filter-popup .rooms .room.more input:checked ~ .rooms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.filter-popup .rooms input {
  display: none;
}
.filter-popup .rooms input:checked + label {
  -webkit-box-shadow: 0 0 0 1px #00a651;
          box-shadow: 0 0 0 1px #00a651;
}
.filter-popup .spoiler {
  border-bottom: 1px solid #d8dee7;
}
.filter-popup .spoiler .header {
  line-height: 50px;
  height: 50px;
  padding: 0 15px;
  font-size: 14px;
  position: relative;
}
.filter-popup .spoiler .header::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 1px solid #323a45;
  border-bottom: 1px solid #323a45;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  right: 20px;
  margin-top: -6px;
}
.filter-popup .spoiler .content {
  display: none;
}
.filter-popup .spoiler .value {
  display: none;
}
.filter-popup .spoiler.changed .header {
  background: #fff;
}
.filter-popup .spoiler.changed .value {
  position: absolute;
  font-size: 14px;
  color: #00a651;
  line-height: 40px;
  top: 0;
  right: 50px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-popup .spoiler.active {
  position: relative;
}
.filter-popup .spoiler.active .value {
  display: none;
}
.filter-popup .spoiler.active .header::before {
  -webkit-transform: none;
          transform: none;
  border: 0;
  height: 40px;
  content: "Готово";
  color: #00a651;
  width: auto;
  top: 0;
  line-height: 40px;
  margin: 0;
}
.filter-popup .spoiler.active .content {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.1);
          box-shadow: 0 5px 5px rgba(0,0,0,0.1);
  position: absolute;
  left: 0;
  width: 100%;
  top: 50px;
  z-index: 1;
  max-height: none;
}
.filter-popup .spoiler.active .content input {
  height: 50px;
  line-height: 50px;
  border: 1px solid #eaedf1;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  display: block;
  width: 100%;
}
.filter-popup .spoiler.active .content .control {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.filter-popup .spoiler.active .content .control .line {
  width: 100%;
  height: 50px;
  position: relative;
}
.filter-popup .spoiler.active .content .control .line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #eaedf1;
  margin-top: -1px;
}
.filter-popup .spoiler.active .content .control .line .point {
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  background: #00a651;
  margin-top: -1px;
}
.filter-popup .spoiler.active .content .control .line .point::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #00a651;
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -10px -10px 0 0;
}
.filter-popup .spoiler.active .content .control.twice .line .point::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #00a651;
  border: 4px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin: -10px 0 0 -10px;
}
.filter-popup .spoiler.active .content .control.twice input {
  width: 45%;
}
.filter-popup .spoiler.active .content .year label {
  padding: 0 25px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-transform: none;
  border-bottom: 1px solid #eaedf1;
}
.filter-popup .spoiler.active .content .year input {
  display: none;
}
.filter-popup .spoiler.active .content .year input:checked + label {
  color: #00a651;
  font-weight: 400;
}
.filter-popup .spoiler.active .content .year:last-child label {
  border-bottom: 0;
}
.filter-popup .spoiler.active .content .item label {
  padding: 0 25px 0 60px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-transform: none;
  border-bottom: 1px solid #eaedf1;
  position: relative;
}
.filter-popup .spoiler.active .content .item label::before {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #eaedf1;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -9px;
}
.filter-popup .spoiler.active .content .item:last-child label {
  border-bottom: 0;
}
.filter-popup .spoiler.active .content .item input {
  display: none;
}
.filter-popup .spoiler.active .content .item input:checked + label::after {
  content: "";
  width: 6px;
  height: 12px;
  border-right: 2px solid #00a651;
  border-bottom: 2px solid #00a651;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -9px;
}
.filter-popup .btn {
  margin: 50px 15px 40px 15px;
  width: calc(100% - 30px);
}