
.bank-popup strong,
.bank-popup p {
  margin-bottom: 15px;
}
.bank-popup input {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 15px;
  border-radius: 5px;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}