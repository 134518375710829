
.map-balloon {
  width: 155px;
}
.map-balloon .image {
  width: 100%;
  margin-bottom: 10px;
}
.map-balloon .image img {
  width: 100%;
  height: auto;
}
.map-balloon .content h1 {
  font-size: 16px;
  margin-bottom: 10px;
}
.search .map {
  height: 50vw;
}
.search .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #eaedf1;
}
.search .links .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 40px;
  height: 40px;
  color: inherit;
  text-decoration: none;
  width: 60%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #d8dee7;
  font-size: 14px;
}
.search .links .link:last-child {
  border-right: 0;
  width: 40%;
}
.search .links .link.back {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
}
.search .links .link.back::before {
  content: "";
  width: 10px;
  height: 10px;
  border-left: 2px solid #00a651;
  border-bottom: 2px solid #00a651;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: -6px 0 0 -5px;
}
.search .links .icon {
  margin-right: 10px;
}
.search .links .icon.search {
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,28,28%27%3E%3Cg%20fill=%27none%27%20stroke=%27%2300a651%27%3E%3Cpath%20stroke-width=%272%27%20d=%27m8,1a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5zm13,10.5a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5zm-13,10.5a2.5,2.5,0,0,1,0,5,2,2,0,0,1,0-5z%27/%3E%3Cpath%20stroke-width=%273%27%20d=%27m0,3.5h5m6,0h17m0,10.5h-5m-5,0h-18m0,10.5h5m6,0h17%27/%3E%3C/g%3E%3C/svg%3E");
}
.search .links .icon.map {
  width: 11px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,22,32%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%2300a651%27%20stroke-width=%273%27%20d=%27m1.5,11a9.5,9.5,0,0,1,19,0,9.5,9.5,0,0,1-1,4l-8.5,14-8.5-14a9.5,9.5,0,0,1-1-4zm5.5,0a4,4,0,0,1,8,0,4,4,0,0,1-8,0z%27/%3E%3C/svg%3E");
}
.search .selection-title {
  background: #d8dee7;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
.search .search-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #d8dee7;
}
.search .search-tabs label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0 10px;
  font-size: 141x;
  border-right: 1px solid rgba(0,0,0,0.2);
}
.search .search-tabs label + label {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}
.search .search-tabs label span {
  line-height: 30px;
  height: 30px;
  padding: 0 9px 0 8px;
  display: block;
  color: #fff;
  background: #323a45;
  margin-right: 10px;
}
.search .search-tabs input {
  display: none;
}
.search .search-tabs input:checked + label {
  background: #fff;
}
.search .search-tabs input:checked + label span {
  background: #00a651;
}
.search .search-tabs .sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 50px;
  width: 50px;
  min-width: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.search .search-tabs .sort .icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cpath%20fill=%27none%27%20stroke=%27%23323a45%27%20stroke-width=%273%27%20d=%27m42,8v28m-12-12,12,12,12-12m-40,14v-28m-12,12,12-12,12,12%27/%3E%3C/svg%3E");
  width: 28px;
  height: 23px;
}
.search .search-tabs .sort .icon.alphabet.asc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,8v28m-12-12,12,12,12-12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20d=%27m2,18.5,7-18,7,18m-2-5h-10%27/%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m15,46v-18h-7a4.5,4.5,0,0,0,0,9h7m-8,0,-4,9%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .icon.alphabet.desc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,36v-28m-12,12,12-12,12,12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20stroke=%27green%27%20d=%27m15,18.5v-18h-7a4.5,4.5,0,0,0,0,9h7m-8,0,-4,9%27/%3E%3Cpath%20d=%27m2,45.5,7-18,7,18m-2-5h-10%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .icon.price.asc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,8v28m-12-12,12,12,12-12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20d=%27m8,17v-16h5a4,4,0,0,1,0,8h-5m-3,4h9%27/%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m6,46v-20h6a6,6,0,0,1,0,12h-6m-3,4h9%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .icon.price.desc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,36v-28m-12,12,12-12,12,12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m6,21v-20h6a6,6,0,0,1,0,12h-6m-3,4h9%27/%3E%3Cpath%20d=%27m8,46v-16h5a4,4,0,0,1,0,8h-5m-3,4h9%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .icon.date.asc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,8v28m-12-12,12,12,12-12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20d=%27m12,1a7,7,0,0,1,0,14,7,7,0,0,1,0-14zm0,1.5v5.5h3.5%27/%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m12,27a9,9,0,0,1,0,18,9,9,0,0,1,0-18zm0,1.5v7.5h-4.5%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .icon.date.desc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270,0,56,46%27%3E%3Cg%20fill=%27none%27%20stroke=%27%23323a45%27%3E%3Cpath%20stroke-width=%273%27%20d=%27m42,36v-28m-12,12,12-12,12,12%27/%3E%3Cg%20stroke-width=%272%27%20stroke-linejoin=%27bevel%27%3E%3Cpath%20d=%27m12,31a7,7,0,0,1,0,14,7,7,0,0,1,0-14zm0,1.5v5.5h3.5%27/%3E%3Cpath%20stroke=%27%2300a651%27%20d=%27m12,1a9,9,0,0,1,0,18,9,9,0,0,1,0-18zm0,1.5v7.5h-4.5%27/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.search .search-tabs .sort .change {
  position: absolute;
  top: 50px;
  left: 0;
  width: 50px;
  background: #d8dee7;
}
.search .search-tabs .sort .change .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  line-height: 50px;
  width: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 1px solid rgba(0,0,0,0.2);
}
.search .show-more {
  padding: 20px 15px;
}