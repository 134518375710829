
.popup-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
}
.popup-block .shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
}
.popup-block .popup {
  padding: 40px 15px 20px;
  background: #eaedf1;
  min-width: 100%;
  max-width: 100%;
  min-height: 300px;
  z-index: 11;
  position: relative;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.popup-block .content {
  overflow: scroll;
  max-height: 100%;
  position: relative;
  width: 100%;
}
.popup-block .close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 13;
}
.popup-block .close::before,
.popup-block .close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid #323a45;
  width: 25px;
  margin: -2px 0 0 -15px;
}
.popup-block .close::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.popup-block .close::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}