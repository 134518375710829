
.users {
  padding: 20px;
  background: #d8dee7;
}
.users .btn {
  margin-bottom: 15px;
}
.users .user {
  display: block;
  margin-bottom: 10px;
}