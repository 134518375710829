
.user-page {
  padding: 20px;
  background: #d8dee7;
}
.user-page .form-item {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 15px;
  border-radius: 5px;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}
.user-page .email {
  margin-bottom: 15px;
  font-weight: bold;
}
.user-page h3,
.user-page .admin {
  margin-bottom: 15px;
  display: block;
}
.user-page .delete {
  margin-top: 15px;
  background: #f78906;
}